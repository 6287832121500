// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;

// IM-737
$('document').ready(function(){
    $('.menuMainAlt').appendTo('.mainCallToActionButtons');
})